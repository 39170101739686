<template lang="pug">
	section#registrar
		Aside(:ativo="isDocumentInvalid", @close="errors.documento = []").documento-cadastrado
			h2 Documento já cadastrado!
			p Caso possua uma conta e não lembre sua senha, pode recuperá-la
				router-link(:to="{ name: 'esqueceuSenha' }") aqui
				| .
			p Se acredita que isto se trata de um erro, por favor entre em contato através do e-mail
				a(href="mailto:contato@paliativo.com.br", target="_blank") contato@paliativo.com.br
				| .
		.wrapper
			div.registre
				h2 Registre
				//- p Lorem ipsum dolor sit amet, consectetur adip incididunt ut labore et dolore magna aliqua.
				form(@submit.prevent="sendRegistrar")
					.categoria.perfil
						label(for="foto" v-cloak @drop.prevent="updateImage" @dragover.prevent, ref="labelImagem")
							img(:src="require('@images/icones/icone-foto.png')", ref="imagePerfil")
						input(name="input_foto_perfil", type="File" id="foto" @change="updateImage()" ref="inputImage")

					div.categoria
						div.input._25(:class="{error: errors.categoria_id}").required
							select(v-model="registrar.categoria_id")
								option(:value="null", disabled) Categoria
								option(:value="categoria.id", v-for="categoria in categorias", v-if="categoria") {{categoria.nome}}
							span(v-if="errors.categoria_id") {{errors.categoria_id[0]}}

					div.categoria.pessoal
						h3 Dados Pessoais

						div.input._25(:class="{error: errors.nome}").required
							input(name="input_nome", type="text", autocomplete="off", autocorrect="off", placeholder="Nome", v-model="registrar.nome")
							span(v-if="errors.nome") {{errors.nome[0]}}

						div.input._25(:class="{error: errors.sobrenome}").required
							input(name="input_sobrenome", type="text", autocomplete="off", autocorrect="off", placeholder="Sobrenome", v-model="registrar.sobrenome")
							span(v-if="errors.sobrenome") {{errors.sobrenome[0]}}

						div.input._25(:class="{error: errors.documento}").required
							input(name="input_documento", type="tel", autocomplete="off", autocorrect="off", placeholder="CPF/CNPJ" v-model="registrar.documento" v-mask="['###.###.###-##', '##.###.###/####-##']")
							span(v-if="errors.documento") {{errors.documento[0]}}

						div.input._25(:class="{error: errors.data_nascimento}").required
							input(name="input_data_nascimento", type="text", autocomplete="off", autocorrect="off", placeholder="Data de Nascimento" v-model="registrar.data_nascimento" v-mask="['##/##/####']")
							span(v-if="errors.data_nascimento") {{errors.data_nascimento[0]}}



						div.input._25(:class="{error: errors.telefone}").required
							input(name="input_telefone", type="tel", autocomplete="off", autocorrect="off", placeholder="Telefone" v-model="registrar.telefone" v-mask="['(##) #####-####','(##) ####-####']")
							span(v-if="errors.telefone") {{errors.telefone[0]}}

						div.input._25(:class="{error: errors.rg}")
							input(name="input_rg", type="text", autocomplete="off", autocorrect="off", placeholder="RG" v-model="registrar.rg", v-mask="'XXXXXXXXXXXXXXXXXXXX'")
							span(v-if="errors.rg") {{errors.rg[0]}}

						div.input._25(:class="{error: errors.sexo}", v-if="!isCnpj")
							select(v-model="registrar.sexo")
								option(:value="null", disabled) Gênero
								option(value="Masculino") Masculino
								option(value="Feminino") Feminino
								option(value="Outros") Outros
								option(value="Prefiro não informar") Prefiro não informar
							span(v-if="errors.sexo") {{errors.sexo[0]}}

						div.input._25(:class="{error: errors.estado_cnpj_id}", v-if="isCnpj").required
							select(v-model="registrar.estado_cnpj_id")
								option(:value="null", disabled) Estado de registro CNPJ
								option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
							span(v-if="errors.estado_cnpj_id") {{errors.estado_cnpj_id[0]}}

					div.categoria
						h3 Dados de acesso
						div.input._50(:class="{error: errors.email}").required
							input(name="input_email", type="email", autocomplete="off", autocorrect="off", placeholder="E-mail", v-model="registrar.email")
							span(v-if="errors.email") {{errors.email[0]}}

						div.input._25(:class="{error: errors.password}").required
							input(name="input_password", type="password", autocomplete="off", autocorrect="off", placeholder="Senha" v-model="registrar.password")
							span(v-if="errors.password") {{errors.password[0]}}

						div.input._25(:class="{error: errors.re_password}").required
							input(name="input_re_password", type="password", autocomplete="off", autocorrect="off", placeholder="Confirmação de senha" v-model="registrar.re_password")
							span(v-if="errors.re_password") {{errors.re_password[0]}}
						p
							|A senha deve conter: de 6 a 12 caracteres, sendo ao menos uma maiúscula, uma minúscula, um número e um caracter especial(@!*$#%.)

					.categoria
						h3 Dados de endereço

						div.input._25(:class="{error: errors.cep}").required
							input(name="input_cep", type="tel", autocomplete="off", autocorrect="off", placeholder="CEP" v-model="registrar.cep" v-mask="['##.###-###']", @change="loadEnderecoViaCep()")
							span(v-if="errors.cep") {{errors.cep[0]}}

						div.input._25(:class="{error: errors.endereco}").required
							input(name="input_endereco", type="text", autocomplete="off", autocorrect="off", placeholder="Endereço" v-model="registrar.endereco")
							span(v-if="errors.endereco") {{errors.endereco[0]}}

						div.input._25(:class="{error: errors.numero}")
							input(name="input_numero", type="tel", autocomplete="off", autocorrect="off", placeholder="Número" v-model="registrar.numero")
							span(v-if="errors.numero") {{errors.numero[0]}}

						div.input._25(:class="{error: errors.complemento}")
							input(name="input_complemento", type="text", autocomplete="off", autocorrect="off", placeholder="Complemento" v-model="registrar.complemento")
							span(v-if="errors.complemento") {{errors.complemento[0]}}

						div.input._25(:class="{error: errors.estado_id}")
							select(v-model="registrar.estado_id", @change="loadCidades(registrar.estado_id)")
								option(:value="null", disabled) Estado
								option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
							span(v-if="errors.estado_id") {{errors.estado_id[0]}}

						div.input._25(:class="{error: errors.cidade_id}").required
							select(v-model="registrar.cidade_id", :disabled="!cidades.length")
								option(:value="null", disabled) Cidade
								option(:value="cidade.id", v-for="cidade in cidades", v-if="cidades") {{cidade.nome}}
							span(v-if="errors.cidade_id") {{errors.cidade_id[0]}}

						div.input._25(:class="{error: errors.bairro}")
							input(name="input_bairro", type="text", autocomplete="off", autocorrect="off", placeholder="Bairro" v-model="registrar.bairro")
							span(v-if="errors.bairro") {{errors.bairro[0]}}

					.categoria(v-if="registrar.categoria_id && registrar.categoria_id !== categoria_estudante_id")
						h3 Dados Profissionais

						div.input._25(:class="{error: errors.profissao_id}")
							select(v-model="registrar.profissao_id", @change="loadConselhos(registrar.profissao_id)")
								option(:value="null", disabled) Profissão
								option(:value="profissao.id", v-for="profissao in profissoes", v-if="profissoes") {{profissao.nome}}
							span(v-if="errors.profissao_id") {{errors.profissao_id[0]}}

						div.input._25(:class="{error: errors.conselho_id}", v-if="registrar.profissao_id !== profissao_outros_id")
							select(v-model="registrar.conselho_id", :disabled="!conselhos.length")
								option(:value="null", disabled) Conselho
								option(:value="conselho.id", v-for="conselho in conselhos", v-if="conselho") {{conselho.nome}}
							span(v-if="errors.conselho_id") {{errors.conselho_id[0]}}

						div.input._50(:class="{error: errors.profissao_texto}", v-if="registrar.profissao_id === profissao_outros_id")
							input(type="text", placeholder="Profissão", v-model="registrar.profissao_texto")
							span(v-if="errors.profissao_texto") {{errors.profissao_texto[0]}}
						div.input._25(:class="{error: errors.conselho_texto}", v-if="registrar.profissao_id === profissao_outros_id")
							input(name="input_conselho_texto", type="text", placeholder="Conselho" v-model="registrar.conselho_texto")
							span(v-if="errors.conselho_texto") {{errors.conselho_texto[0]}}

						div.input._25(:class="{error: errors['informacoes_adicionais.profissional.estado_id']}")
							select(v-model="temp.profissional.estado_id")
								option(:value="null", disabled) Estado
								option(:value="estado.id", v-for="estado in estados", v-if="estados") {{estado.nome}}
							span(v-if="errors['informacoes_adicionais.profissional.estado_id']") {{errors['informacoes_adicionais.profissional.estado_id'][0]}}

						div.input._25(:class="{error: errors.numero_conselho}")
							input(name="input_numero_conselho", type="text", autocomplete="off", autocorrect="off", placeholder="Registro no Conselho" v-model="conselho")
							span(v-if="errors.numero_conselho") {{errors.numero_conselho[0]}}

						div.input._25(:class="{error: errors.especialidade}")
							input(name="input_especialidade", type="text", autocomplete="off", autocorrect="off", placeholder="Especialidade" v-model="registrar.especialidade")
							span(v-if="errors.especialidade") {{errors.especialidade[0]}}

						div.input(:class="{error: errors['informacoes_adicionais.profissional.empresa']}")
							input(name="input_empresa_atual", type="text", autocomplete="off", autocorrect="off", placeholder="Nome da Instituição de Trabalho" v-model="registrar.informacoes_adicionais.profissional.empresa")
							span(v-if="errors['informacoes_adicionais.profissional.empresa']") {{errors['informacoes_adicionais.profissional.empresa'][0]}}

						div.input(:class="{error: errors['informacoes_adicionais.profissional.contato']}", v-if="isCnpj")
							input(name="input_empresa_contato", type="text", autocomplete="off", autocorrect="off", placeholder="Pessoa para Contato" v-model="registrar.informacoes_adicionais.profissional.contato")
							span(v-if="errors['informacoes_adicionais.profissional.contato']") {{errors['informacoes_adicionais.profissional.contato'][0]}}

					.categoria(v-if="registrar.categoria_id && registrar.categoria_id === categoria_estudante_id")
						h3 Dados Acadêmicos

						div.input(:class="{error: errors['informacoes_adicionais.academico.curso']}").required
							input(name="input_curso", type="text", autocomplete="off", autocorrect="off", placeholder="Curso" v-model="registrar.informacoes_adicionais.academico.curso")
							span(v-if="errors['informacoes_adicionais.academico.curso']") {{errors['informacoes_adicionais.academico.curso'][0]}}

						div.input.checkbox._50
							input(type="checkbox", id="input_is_estudante_liga" v-model="registrar.is_estudante_liga")
							label(for="input_is_estudante_liga") Sou estudante de Liga Acadêmica

						div.input.document._25
							label(for="input_document" v-cloak @drop.prevent="updateDocument($event, 'documento_comprovante')" @dragover.prevent, ref="labelDocument")
								img(:src="require('@images/icones/icone-documento.png')")
								|{{nomeDocumento}}
							input(name="input_document", type="File" id="input_document" @change="updateDocument(null, 'documento_comprovante')" ref="documento_comprovante")

						div.input.document._25(v-if="registrar.is_estudante_liga")
							label(for="input_document_liga" v-cloak @drop.prevent="updateDocument($event, 'documento_comprovante_liga')" @dragover.prevent, ref="labelDocumentLiga")
								img(:src="require('@images/icones/icone-documento.png')")
								|{{nomeDocumentoLiga}}
							input(name="input_document_liga", type="File" id="input_document_liga" @change="updateDocument(null, 'documento_comprovante_liga')" ref="documento_comprovante_liga")

					div.input.checkbox
						input(type="checkbox", id="input_termos_lidos" v-model="registrar.termos_lidos")
						label(for="input_termos_lidos")
							| Declaro que li
							router-link(:to="{ name: 'termoDeUso' }", target="_blank") Termos de Uso
							| ,
							router-link(:to="{ name: 'politicadeprivacidade' }", target="_blank") Políticas de Privacidade
							|  e o
							a(:href="$configuracoes.configuracoes.estatuto_academia", v-if="$configuracoes.configuracoes" target="_blank") Estatuto da Academia
						span(v-if="!registrar.termos_lidos") Obrigatório

					div.input.checkbox._50
						input(type="checkbox", id="input_receber_infos_email" v-model="registrar.receber_infos_email")
						label(for="input_receber_infos_email") Desejo receber boletins e informativos da ANCP via e-mail

					div.input.checkbox._50
						input(type="checkbox", id="input_receber_infos_whatsapp" v-model="registrar.receber_infos_whatsapp")
						label(for="input_receber_infos_whatsapp") Desejo receber boletins e informativos da ANCP via WhatsApp

					div.input.checkbox(:class="{'erro': errors.informacoes_legais}")
						input(type="checkbox", id="input_informacoes_legais" v-model="registrar.informacoes_legais")
						label(for="input_informacoes_legais") Declaro, para os devidos fins e sob as penas da lei, que os dados preenchidos e contidos no presente formulário, bem como os documentos entregues eletronicamente à ACADEMIA NACIONAL DE CUIDADOS PALIATIVOS, são integralmente verídicos, autênticos e condizem com a documentação original.
						span(v-if="!registrar.informacoes_legais") Obrigatório

					button.registrar(:disabled="sending || !registrar.termos_lidos || !registrar.informacoes_legais") {{ buttonText }}
				// span Verifique os campos destacados*
</template>

<script>
import { mask } from "vue-the-mask";
// import Axios from "axios";
import Aside from "@components/Aside/Aside"

export default {
	directives: { mask },
	components: {
		Aside,
	},
	data() {
		return {
			temp: {
				profissional: {
					estado_id: null,
				}
			},
			sending: false,
			buttonText: "Enviar",
			registrar: {
				documento: '',
				cep: '',
				nome: '',
				email: '',
				bairro: '',
				is_estudante_liga: false,
				receber_infos_email: false,
				receber_infos_whatsapp: false,
				termos_lidos: false,
				documento_comprovante: null,
				documento_comprovante_liga: null,
				foto_perfil_usuario: null,
				cidade_id: null,
				estado_id: null,
				profissao_id: null,
				conselho_id: null,
				estado_cnpj_id: null,
				sexo: null,
				endereco: '',
				informacoes_adicionais: {
					profissional: {},
					academico: {}
				},
				categoria_id:null,
				numero_conselho:''
			},
			cidades: [],
			estados: [],
			profissoes: [],
			conselhos: [],
			categorias:[],
			categoria_estudante_id:null,
			profissao_outros_id:null,
			errors: {},
		};
	},
	created() {
		this.loadEstados();
		this.loadCategorias();
		this.loadProfissoes();
	},
	mounted() {
		if (this.$route.query.redirectTo)
			this.redirectTo = this.$route.query.redirectTo
		if (this.$route.query.nome)
			this.registrar.nome = this.$route.query.nome
		if (this.$route.query.email)
			this.registrar.email = this.$route.query.email
	},
	computed: {
		conselhoPrefix(){
			let prefix = '';
			let conselho = false;
			if(this.profissao_outros_id === this.registrar.profissao_id){
				if(this.registrar.conselho_texto)
					prefix += this.registrar.conselho_texto
			}else{
				if(this.registrar.conselho_id && (conselho = this.findById(this.conselhos,this.registrar.conselho_id))){
					prefix += conselho.nome;
				}
				let estado = false;
				if(this.temp.profissional.estado_id && (estado = this.findById(this.estados,this.temp.profissional.estado_id))){
					if(prefix !== '')
						prefix += '/';
					prefix += estado.uf;
				}
			}
			if(prefix !== '')
				prefix += ' ';
			return prefix;
		},
		conselho: {
			get(){
				if(this.registrar.numero_conselho.startsWith(this.conselhoPrefix))
					return this.registrar.numero_conselho;
				return this.conselhoPrefix;
			},
			set(value){
				this.registrar.numero_conselho = value;
			}
		},
		nomeDocumento() {
			return this.registrar.documento_comprovante ? this.registrar.documento_comprovante.name : 'Declaração de matrícula'
		},
		nomeDocumentoLiga() {
			return this.registrar.documento_comprovante_liga ? this.registrar.documento_comprovante_liga.name : 'Declaração do Professor da Liga'
		},
		isCnpj() {
			return this.registrar.documento.length > 14 ? true : false;
		},
		isDocumentInvalid() {
			if (typeof this.errors.documento !== 'undefined' && this.errors.documento.includes(`documento já cadastrado`))
				return true
			return false
		},
	},
	methods: {
		loadEnderecoViaCep() {
			let cep = this.registrar.cep.replace('-', '').replace('.', '')
			if (cep.length === 8)
				this.$axios.get(`https://viacep.com.br/ws/${cep}/json/`)
					.then(response => {
						this.registrar.bairro = response.data.bairro;
						this.registrar.estado_id = this.estados.find(estado => estado.uf == response.data.uf).id;
						this.registrar.endereco = response.data.logradouro;
						this.$axios
							.get(`api/cidades/${this.registrar.estado_id}`)
							.then(responseCidades => {
							this.cidades = responseCidades.data;
								this.registrar.cidade_id = responseCidades.data.find(cidade => cidade.nome == response.data.localidade).id;
							})
					})
					.catch(response => {
						console.log(response) // eslint-disable-line
					})
		},
		loadEstados() {
			this.$axios
				.get(`api/estados`)
				.then(response => this.estados = response.data)
		},
		loadCidades(estado_id, lista = 'cidades') {
			this.$axios
				.get(`api/cidades/${estado_id}`)
				.then(response => this.$data[lista] = response.data)
		},
		loadCategorias() {
			this.$axios
				.get(`api/categorias`)
				.then(response =>{
					this.categorias = response.data.registros;
					this.categoria_estudante_id = response.data.categoria_estudante_id;
				});
		},
		loadProfissoes() {
			this.$axios
				.get(`api/profissoes`)
				.then(response => {
					this.profissoes = response.data;
					for(let i = 0; i < this.profissoes.length; i++){
						if(this.profissoes[i].nome === "Outros"){
							this.profissao_outros_id = this.profissoes[i].id;
						}
					}
				});
		},
		loadConselhos(profissao_id) {
			this.$axios
				.get(`api/profissoes/${profissao_id}/conselhos`)
				.then(response => this.conselhos = response.data)
		},
		updateImage(e = null) {
			var reader = new FileReader();
			this.registrar.foto_perfil_usuario = e ? e.dataTransfer.files[0] : this.$refs.inputImage.files[0]
			reader.onload = image => {
				this.$refs.imagePerfil.src = image.target.result;
			};
			reader.readAsDataURL(
				e ? e.dataTransfer.files[0] : this.$refs.inputImage.files[0]
			);
		},
		updateDocument(e = null, documento = 'documento_comprovante') {
			this.registrar[documento] = e ? e.dataTransfer.files[0] : this.$refs[documento].files[0]
		},
		sendRegistrar() {
			let formData = new FormData();
			if (this.temp.profissional.estado_id)
				this.registrar.informacoes_adicionais.profissional.estado_id = this.temp.profissional.estado_id;

			Object.keys(this.registrar).forEach(item => {
				if (this.registrar[item] != null) {
					if (item == 'informacoes_adicionais') {
						formData.append(item, JSON.stringify(this.registrar[item]))
					} else {
						formData.append(item, this.registrar[item])
					}
				}
			})

			this.buttonText = "Enviando...";
			this.sending = true;
			this.$axios
				.post(`api/registrar`, formData)
				// eslint-disable-next-line
				.then(response => {
					this.buttonText = "Registrado com sucesso!";
					setTimeout(() => {
						this.$router.push('/')
					}, 2500)
				})
				.catch(error => {
					this.buttonText = "Enviar";
					this.sending = false;
					if (error.response.status == 422)
						this.errors = error.response.data.errors;
					document.querySelector(".categoria").scrollIntoView({
						behavior: 'smooth'
					});
				})
				.finally(() => {
					setTimeout(() => {
						this.buttonText = "Enviar";
						this.sending = false;
					}, 3000)
				});
		},
		findById(arrayData,searchId){
			for(let i = 0; i < arrayData.length; i++){
				if(arrayData[i] && arrayData[i].id == searchId)
					return arrayData[i];
			}
			return false;
		}
	}
};
</script>

<style lang="stylus" scoped src="./Registrar.styl"></style>
